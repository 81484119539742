@charset "utf-8";/* CSS Document */
*{margin: 0;padding: 0;}
html {font-size: 62.5%;height: 100%}
body {margin: 0 auto;font-family: 'OpenSansRegular';font-size: 14px;font-size: 1.4rem;height: 100%;overflow-x: hidden;max-width: 1920px;}
img {border: none;}
ul li {list-style-type: none;}
ul, form, p, a, img, table, tr, td, li, dd, dt, dl, span {margin: 0;padding: 0;list-style: none;color: #333;}
a {text-decoration: none;color: #333;outline: none;transition: 0.3s;-moz-transition: 0.3s;-o-transition: 0.3s;-webkit-transition: 0.3s;}
h1 {margin: 0;padding: 0;font-weight: normal;color: #333;}
.clear {clear: both;}
.maln {margin-left: auto !important}
input, textarea {font-family: 'OpenSansRegular';font-size: 14px;font-size: 1.4rem;color:#333;border: none;outline: none;}
.l{ float:left;}
.r{ float:right;}
.fix{*zoom:1; } .fix:after,.fix:before{display:block; content:"clear"; height:0; clear:both; overflow:hidden; visibility:hidden; }

::-webkit-input-placeholder {/* WebKit browsers */
 color:#1b1b1b;}
:-o-placeholder {/* Mozilla Firefox 4 to 18 */
 color:#1b1b1b;}
::-moz-placeholder {/* Mozilla Firefox 19+ */
 color:#1b1b1b;}
:-ms-input-placeholder {/* Internet Explorer 10+ */
 color:#1b1b1b;}
 
.wp {width: 1200px;margin: auto;}
@media screen and (min-width:1220px) {.wp {width: 1200px;margin: auto;}}
@media screen and (min-width:1000px) and (max-width:1219px) {.wp {width: 980px;margin: auto;}}
@media screen and (max-width:999px) {.wp {width: 95%;margin: auto;}}

.no_margin{margin-left: auto!important\0;}
@media screen and (min-width:1220px) {.no_margin{margin-left: auto!important;}}
@media screen and (min-width:1000px) and (max-width:1219px) {.no_margin{margin-left: auto!important;}}

/*.container*/
.container { width: 5px; height: 100%; position: absolute; right: 0; -webkit-transition: all 0.4s ease 0s; transition: all 0.4s ease 0s; z-index: 999999999999999; cursor: pointer; position: fixed; top: -5px; right: 0; display: block; }

.container .nav { overflow: hidden; width: 300px; background: rgba(0, 0, 0, 0.8); position: absolute; top: 0; right: 0; display: block; height: auto; overflow: scroll; }

.container .nav form { margin: 15px auto; width: 258px; height: 26px; border: 1px solid #fff; }

.container .nav form .iptc { box-sizing: border-box; padding-left: 15px; background-color: #fff; width: 80%; height: 26px; line-height: 26px; display: block; float: left; }

.container .nav form .ipsc { width: 20%; height: 26px; line-height: 26px; display: block; float: right; background: #fff url(../images/sousuo.png) no-repeat center center; }

.container .nav > ul { display: none; margin-top: 0px; margin-bottom: 0px; overflow: scroll; padding-top:45px;-ms-box-sizing: border-box;-o-box-sizing: border-box;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}

.container .nav > ul > li { line-height: 40px; font-size: 12px; font-size: 1.2rem; text-indent: 20px; position: relative; }

.container .nav > ul > li .addition { width: 20px; height: 20px; position: absolute; right: 15px; top: 10px; background: url("fonts/shizi.png") no-repeat center center;  }

.container .nav > ul > li .addition.fa:before { margin-right: 5px; color: #fff; text-indent: 0; }

.container .nav > ul > li > a { color: #fff; font-size: 12px; font-size: 1.2rem; display: block; margin-right: 40px;}

.container .nav > ul > li > a span { color: #fff; font-size: 12px; font-size: 1.2rem; }

.container .nav > ul > li .c-show { display: none; width: 100%; }


.container .nav > ul > li .c-show1 .c-s1 { position: relative; padding-left: 15px;}

.container .nav > ul > li .c-show1 .c-s1 > a {position: relative;  font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; margin-right: 40px;}

.container .nav > ul > li .c-show1 .c-s1 > a span { font-size: 12px; font-size: 1.2rem; color: #fff; }


.container .nav > ul > li .c-show1 .c-s1 > .c-show2 {position: relative;}

.container .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2{position: relative; }

.container .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 > a { text-indent: 35px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; margin-right: 40px;}

.container .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 > a  span { font-size: 12px; font-size: 1.2rem; color: #fff; }


.container .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 {position: relative;}

.container .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a { text-indent: 50px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; }

.container .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a span { font-size: 12px; font-size: 1.2rem; color: #fff; }


.container .nav > ul > dl{padding: 20px;}

.container .nav > ul > dl dd{float: left;margin: 0 2px;}

.container .menubtn { width: 23px; height: 18px; position: absolute; top: 12px; right: 12px; -webkit-transition: all 0.4s ease 0s; transition: all 0.4s ease 0s; z-index: 9999; }

.container .menubtn span { display: block; width: 100%; height: 3px; margin-bottom: 3px; background-color: #e82023; }

@media screen and (min-width: 768px) { .container, .nav { display: none!important; } }


/***********  lunbo  *************/
*{margin: 0;padding:0;}
li{list-style: none;}

.slide { position: relative;}

.slide .carouse { width: 100%; overflow: hidden; position: relative; }

.slide .carouse .slideItem { width: 100%; position: absolute; cursor: pointer; }

.slide .carouse .slideItem .banner-img { width: 100%;display: block; }

.slide .console { height: 57px; display: inline-block; width: 35px; position: absolute; top: 40%; }

.slide .dotList { position: absolute; width: 100%; bottom: 11px; text-align: center; }

.slide .dotList ul li { display: inline-block; width: 14px; height: 14px; background:#fff; margin: 0 8px; cursor: pointer; }

.slide .carousel-control { position: absolute; top: 50%; width: 60px; height: 60px; z-index: 999; margin-top: -30px;}

.slide .carousel-control.left { left: 8%; background-image: url("../images/prev.png"); background-repeat: no-repeat; cursor: pointer; }

.slide .carousel-control.left:hover { background-image: url("../images/prev2.png"); }

.slide .carousel-control.right { right: 8%; background-image: url("../images/next.png"); background-repeat: no-repeat; cursor: pointer; }

.slide .carousel-control.right:hover { background-image: url("../images/next2.png"); }

.slide .dotList ul li{cursor: pointer; background:url(../images/dian1.png) no-repeat center center;  font-size: 0px;   margin-left: 1px;margin-right: 1px; border: none;outline: none;width: 22px;height:22px;}
.slide .dotList ul li.active{background:url(../images/dian2.png) no-repeat center center;}

.slide .slidedetail { display: none; }

@media screen and (max-width:999px) {
    .slide .carousel-control{display: none;}
}
@media screen and (max-width:767px) {
    .slide .dotList ul li{width: 30px;}
    .slide .dotList{bottom: 15px;}
}

/***********  head  ************/
@font-face {
    font-family: 'OPENSANSSEMIBOLD';
    src: url('../fonts/OPENSANSSEMIBOLD.eot');
    src: url('../fonts/OPENSANSSEMIBOLD.eot') format('embedded-opentype'),
         url('../fonts/OPENSANSSEMIBOLD.woff2') format('woff2'),
         url('../fonts/OPENSANSSEMIBOLD.woff') format('woff'),
         url('../fonts/OPENSANSSEMIBOLD.ttf') format('truetype'),
         url('../fonts/OPENSANSSEMIBOLD.svg#OPENSANSSEMIBOLD') format('svg');
}

@font-face {
    font-family: 'OpenSansRegular';
    src: url('../fonts/OpenSansRegular.eot');
    src: url('../fonts/OpenSansRegular.eot') format('embedded-opentype'),
         url('../fonts/OpenSansRegular.woff2') format('woff2'),
         url('../fonts/OpenSansRegular.woff') format('woff'),
         url('../fonts/OpenSansRegular.ttf') format('truetype'),
         url('../fonts/OpenSansRegular.svg#OpenSansRegular') format('svg');
}

.head{background: #fff;}
.logo{padding-top: 24px;margin-right: 133px;}
.logo img{max-width: 100%;}
.navlist{padding-top: 53px;}
.navlist2{display: none;}
.navlist>li{float: left;position: relative;margin-left: 12px;}
.navlist>li>a{font-size: 16px;font-size: 1.6rem;text-transform: uppercase;display: block;padding: 0 15px;padding-bottom: 52px;}
.navlist>li:hover>a{background: url(../images/navbg.jpg) no-repeat center bottom;color: #e82023;}
.navlist>li.currentnav>a{background: url(../images/navbg.jpg) no-repeat center bottom;color: #e82023;}

.search{margin-top: 49px;position: relative;}
.search form{position: absolute;top: 100%;right: 0;z-index: 99;display: none;}
.search>img{cursor: pointer;}
.sou{width: 200px;height: 28px;line-height: 28px;padding-left: 10px;border: 1px solid #333;}
.suo{position: absolute;right: 5px;top: 5px;width: 18px;height: 18px;background: url(../images/sousuo2.png) no-repeat center center;cursor: pointer;}

@media screen and (max-width:1219px) {
    .logo{margin-right: 40px;}
    .navlist > li > a{padding: 0 8px;padding-bottom: 52px;}
    .navlist > li{margin-left: 6px;}
}
@media screen and (max-width:999px) {
    .navlist{width: 100%;padding-top: 30px;}
    .navlist > li{margin-left: 0;margin-right: 20px;}
    .navlist > li > a{padding-bottom: 20px;}
}
@media screen and (max-width:880px) {
    .navlist > li{margin-right: 8px;}
}
@media screen and (max-width:790px) {
    .navlist > li{margin-right: 0px;}
}
@media screen and (max-width:767px) {
    .navlist2{display: block;}
    .navlist1{display: none;}
    .navlist>li{width: 25%;margin-left: 0;text-align: center;}
    .navlist > li > a{padding-bottom: 15px;}
    #wowslider-container1 .ws_bullets a{margin: 0 6px;}
}
@media screen and (max-width:500px) {
    .logo{width: 120px;padding-top: 12px;margin-right: 0;}
    .navlist{padding-top: 15px;}
    #wowslider-container1 .ws_bullets a{width: 12px;height: 12px;border-radius: 50%;}
    .search{margin-top: 35px;}
    #wowslider-container1 .ws_bullets{bottom: 2px;}
}
@media screen and (max-width:400px) {
    .navlist > li > a{font-size: 12px;font-size: 1.2rem;}
}

/*************  bottom  ************/
.bottom{background: #fff;padding-top: 43px;border-top: 1px solid #d2d2d2;padding-bottom: 36px;}
.bottom p,.bottom li,.bottom a,.bottom span{color: #666666;}
.bottom a:hover{color: #e82023;}
.div>a,.div>span{display: block;font-size:18px;font-size:1.8rem;margin-bottom: 13px;line-height: 26px;color: #333;}
.div1 li{line-height: 32px;}
.bli2,.bli3{float: left;margin-right: 20px;}

.bsjj a{margin-right: 28px;position: relative;transition: 0.5s;top: 0;}
.bsjj{padding-top: 14px;}
.bsjj a:hover{top: -10px;}
.copyright{line-height: 40px;}
.cnzz{padding-left: 10px;}

.div2{text-align: center;padding-top: 42px;}
.div2 img{border: 6px solid #e6e5e6;}
.div2 p{font-size:18px;font-size:1.8rem;line-height: 32px;}

.link{padding-top: 16px;padding-bottom: 16px;line-height: 30px;min-height: 30px;background: #e82023;}
.link a,.link span{color: #fff;}
.link a{padding: 0 20px;}
.link a:hover{color: #fff;}

@media screen and (min-width:1000px) and (max-width:1219px) {
    .div1{width: 750px;}
}
@media screen and (max-width:999px) {
    .div1{width: calc(100% - 180px);}
}
@media screen and (max-width:767px) {
    .div1,.div2{width: 100%;}
    .bottom{padding-top: 20px;padding-bottom: 20px;}
    .div1>span,.div1 ul{display: none;}
    .copyright, .support{line-height: 30px;}
    .link a,.link span{display: none;}
}
@media screen and (max-width:500px) {
    .bsjj a{margin-right: 10px;}
    .div2{padding-top: 20px;}
}

/***********  syp  ***************/
.syp{padding-top: 56px;padding-bottom: 53px;}
.sybt{text-align: center;text-transform: uppercase;font-size: 36px;font-size: 3.6rem;padding-bottom: 26px;background: url(../images/btxian.jpg) no-repeat center bottom;}
.mainly{display: block;max-width: 655px;margin: 0 auto;text-align: center;color: #555555;font-size:16px;font-size:1.6rem;line-height: 26px;margin-top: 28px;}

.ck-slide { position: relative; overflow: hidden; width: 100%; height: 463px; margin: 0 auto; margin-top: 9px; }

.ck-slide ul { margin: 0; padding: 0; list-style-type: none; }

.ck-slide ul.ck-slide-wrapper { position: absolute; width: 100%; height: 463px; top: 0; left: 0; z-index: 1; margin: 0; padding: 0; }

.ck-slide ul.ck-slide-wrapper li { position: absolute; display: none; }

.ck-slidebox { position: absolute; bottom: 0px; right: 50%; margin-right: -66px; z-index: 30; }

.ck-slidebox ul li { float: left; height: 28px; width: 11px; margin: 0 11px; cursor: pointer;font-size:20px;font-size:2rem;color: #555555;font-family: 'OPENSANSSEMIBOLD';}

.ck-slidebox ul li.current { color: #999999;}

.ck-slidebox ul li:hover { color: #999999; }

.sypbox { width: 283px; float: left; margin-left: 22px; }

.sypcon {position: relative;overflow: hidden;}

.syptu img { display: block; width: 100%; max-width: 423px; max-height: 486px; }

.syptu { display: block; }

.sypcover { background: url(../images/sypcover.png);position: absolute;left: 0;top: -100%;width: 100%;height: 100%;transition: 0.5s;}

.sypcover p{width: 204px;height: 56px;line-height: 56px;text-align: center;background: #444;position: absolute;left: 50%;margin-left: -102px;top: 50%;margin-top: -28px;}
.sypcover p a{display: block;margin-top: 7px;height: 42px;line-height: 42px;background: url(../images/yan.png) no-repeat 167px center;}
.sypcover p span{width: 152px;text-align: center;color: #fff;font-size:18px;font-size:1.8rem;border-right: 1px solid #fff;display: block;}
.sypp1{display: block;font-size:18px;font-size:1.8rem;color: #555555;line-height: 26px;text-align: center;margin-top: 26px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.sypbox:hover .sypcover{top: 0;}
.sypbox:hover .sypp1{color: #e82023;}
.m4l { padding-top: 30px; }
.syppart { display: none; }

@media screen and (max-width: 1219px) { 
    .sypbox {width: 228px; } 
    .sypp1{font-size:16px;font-size:1.6rem;}
    .ck-slide{height: 390px;}
    .ck-slide ul.ck-slide-wrapper{height: 390px;}
}

@media screen and (max-width: 999px) { .sypbox { width: 23%; margin: 0 1%; }
    .sypcover{display: none;}
  .ck-slide { height: 340px; }
  .ck-slide ul.ck-slide-wrapper { height: 340px; }
 }
@media screen and (max-width: 800px) { .ck-slide { height: 300px; }
  .ck-slide ul.ck-slide-wrapper { height: 300px; } }

@media screen and (max-width: 767px) { .m4l { display: none; }
  .syppart { display: block; padding-top: 30px; }
  .sypbox { width: 48%; margin-bottom: 20px; }
  .syp { padding-top: 25px; padding-bottom: 10px; }
  .sybt{font-size: 30px;font-size: 3rem;}
}
@media screen and (max-width: 450px) {
    .mainly{font-size:14px;font-size:1.4rem;}
    .sypp1{font-size:14px;font-size:1.4rem;}
    .sybt{font-size:24px;font-size:2.4rem;padding-bottom: 10px;}
}

/***************  syser  ************/
.syser{padding-top: 53px;padding-bottom: 68px;background:#f5f5f5 url(../images/sybg1.jpg) no-repeat center top;}
.syser ul{padding-top: 48px;}
.syser ul li{width: 400px;text-align: center;float: left;}
.syser ul li a{display: block;padding: 0 40px;padding-top: 146px;font-size:16px;font-size:1.6rem;line-height: 26px;height: 78px;overflow: hidden;}

.ali1 a{background: url(../images/syi1.png) no-repeat center top;}
.ali2 a{background: url(../images/syi2.png) no-repeat center top;}
.ali3 a{background: url(../images/syi3.png) no-repeat center top;}
.ali4 a{background: url(../images/syi4.png) no-repeat center top;}
.ali5 a{background: url(../images/syi5.png) no-repeat center top;}
.ali6 a{background: url(../images/syi6.png) no-repeat center top;}

.ali1:hover a{background: url(../images/syi1h.png) no-repeat center top;}
.ali2:hover a{background: url(../images/syi2h.png) no-repeat center top;}
.ali3:hover a{background: url(../images/syi3h.png) no-repeat center top;}
.ali4:hover a{background: url(../images/syi4h.png) no-repeat center top;}
.ali5:hover a{background: url(../images/syi5h.png) no-repeat center top;}
.ali6:hover a{background: url(../images/syi6h.png) no-repeat center top;}

.syser ul li:hover a{color: #e82023;}

@media screen and (max-width: 1219px) {
    .syser ul li{width: 33.333%;}
    .syser ul li a{font-size:14px;font-size:1.4rem;}
}
@media screen and (max-width: 999px) {
    .syser ul li a{padding-left: 10px;padding-right: 10px;}
}
@media screen and (max-width: 600px) {
    .syser ul li {width: 50%;}
    .syser{padding-top: 30px;padding-bottom: 30px;}
}
@media screen and (max-width: 400px) {
    .syser ul li {width: 100%;margin-bottom: 20px;}
    .syser ul li a{height: auto;}
}


/************  sya  ************/
.sya{padding-top: 66px;background:#030d16 url(../images/sybg2.jpg) no-repeat center top;}
.syabt{font-size: 42px;font-size: 4.2rem;text-transform: uppercase;min-width: 445px;background: url(../images/syabg.png) no-repeat left top;margin-bottom: 52px;}
.syabt a{color: #fff;display: block;padding-bottom: 30px;background: url(../images/syaxian.jpg) no-repeat left bottom;}
.logo2{padding-top: 15px;}

.syazuo{padding-right: 14px;padding-top: 16px;background: url(../images/syatubg.png) no-repeat right top;}
.syazuo img{width: 100%;max-width: 725px;max-height: 397px;display: block;}
.syayou{width: 423px;padding-top: 70px;}
.syap1{display: block;color: #fff;font-size:16px;font-size:1.6rem;line-height: 30px;}
.syap2{display: block;width: 150px;height: 44px;line-height: 44px;text-align: center;border-radius: 22px;background: #fff;font-size:16px;font-size:1.6rem;color: #e82023;margin-top: 34px;}
.syap1:hover{color: #e82023;}
.syap2:hover{background: #e82023;color: #fff;}

@media screen and (max-width: 1219px) {
    .syazuo{width: 520px;background-size: 98%;}
    .syayou{padding-top: 55px;}
}
@media screen and (max-width: 999px) {
    .syazuo{width: 48%;}
    .syayou{width: 48%;padding-top: 20px;}
    .syap1{font-size:14px;font-size:1.4rem;}
    .syabt{font-size: 34px;font-size: 3.4rem;}
    .logo2{padding-top: 0;}
    .syap2{margin-top: 15px;}
}
@media screen and (max-width: 800px) {
    .syazuo{float: none;max-width: 725px;width: auto;margin: 0 auto;}
    .syayou{width: 100%;}
    .sya{padding-top: 40px;padding-bottom: 30px;}
    .logo2{display: none;}
    .syabt{width: 100%;min-width: 0;}
}
@media screen and (max-width: 600px) {
    .syabt a{padding-bottom: 19px;}
    .syabt{margin-bottom: 30px;}
}


/***********  syhb  **********/
.syhb{background: url(../images/sybg3.jpg) no-repeat center top;}
.sb1{margin-right: 90px;margin-top: 74px;}
.sb2{margin-right: 135px;margin-top: 74px;}
.sb1 img{width: 100%;display: block;max-width: 238px;max-height: 90px;box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.2);}
.sb2 img{width: 100%;display: block;max-width: 262px;max-height: 90px;box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.2);}
.hong{background: #e82023;width: 236px;height: 242px;}
.hongn{padding-left: 24px;padding-right: 24px;padding-top: 42px;}
.hongn a{display: block;color: #fff;text-align: center;}
.hongp1{font-size: 26px;font-size: 2.6rem;line-height: 30px;font-family: 'OPENSANSSEMIBOLD';text-transform: uppercase;padding-bottom: 16px;background: url(../images/baixian.jpg) no-repeat center bottom;}
.hongp2{font-size:16px;font-size:1.6rem;line-height: 26px;margin-top: 26px;}

@media screen and (max-width: 1219px) {
    .sb1{margin-right: 50px;}
    .sb2{margin-right: 80px;}
}
@media screen and (max-width: 999px) {
    .sb1{width: 200px;margin-top: 55px;}
    .sb2{width: 225px;margin-top: 55px;}
    .hongp1{font-size:20px;font-size:2rem;}
    .hongp2{font-size:14px;font-size:1.4rem;}
    .hongn{padding-top: 20px;}
    .hong{width: 210px;height: 202px;}
}
@media screen and (max-width: 900px) {
    .sb1{width: 180px;margin-top: 55px;margin-right: 30px;}
    .sb2{width: 200px;margin-top: 55px;margin-right: 40px;}
}
@media screen and (max-width: 767px) {
    .sb1{width: 145px;}
    .sb2{width: 163px;}
    .hongp1{font-size:18px;font-size:1.8rem;line-height: 24px;}
    .hongp2{margin-top: 12px;}
    .hongn{padding-left: 12px;padding-right: 12px;}
    .hong{width: 186px;height: 170px;}
}
@media screen and (max-width: 600px) {
    .hong{width: 100%;height: auto;}
    .hongn{padding-bottom: 20px;}
    .syhb{text-align: center;}
    .sb1{margin-top: 20px;margin-right: 0;width: 40%;}
    .sb2{margin-right: 0;float: right;margin-top: 20px;width: 45%;}
}
@media screen and (max-width: 400px) {
    .sb1{width: 45%;}
    .sb2{width: 50%;}
}

/*************  syn  *************/
.syn{padding-top: 120px;padding-bottom: 177px;background: url(../images/sybg4.png) no-repeat center top;}
.synbai{padding-left: 13px;padding-bottom: 16px;background: url(../images/synbg.png) no-repeat left bottom;margin-top: 113px;}
.synbain{background: #fff;padding-top: 24px;padding-left: 21px;padding-right: 19px;padding-bottom: 39px;}
.synbox{width: 274px;float: left;margin-left: 17px;padding-bottom: 26px;border-bottom: 1px solid #bfbfbf;}
.synp1{display: block;font-size:16px;font-size:1.6rem;line-height: 26px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-top: 13px;margin-bottom: 15px;}
.synp2{color: #555555;line-height: 26px;display: block;height: 52px;overflow: hidden;margin-top: 9px;}
.synbox span{font-size:14px;font-size:1.4rem;color: #888888;}
.synbox img{display: block;width: 100%;max-width: 274px;max-height: 178px;}
.synp1:hover{color: #e82023;}
.synp2:hover{color: #e82023;}

@media screen and (max-width: 1219px) {
    .synbox{width: 219px;}
}
@media screen and (max-width: 999px) {
    .synbox{width: 48%;margin: 0 1%;margin-bottom: 20px;text-align: center;}
    .synbox img{display: inline-block;}
    .syn{padding-top: 50px;padding-bottom: 70px;}
    .synbai{margin-top: 30px;}
}
@media screen and (max-width: 600px) {
    .syn{padding-top: 30px;padding-bottom: 15px;}
    .synbain{padding-bottom: 15px;}
}
@media screen and (max-width: 500px) {
    .synbai{padding-left: 0;padding-bottom: 0;background: none;}
    .synbain{padding: 0;}
    .syn{background: none;}
}
@media screen and (max-width: 400px) {
    .synbox{width: 98%;}
}

/************  bread  *************/
.mianbao{ font-size:14px; font-size:1.4rem; line-height:24px;padding-top:12px;border-bottom: 1px solid #bfbfbf;padding-bottom: 10px;text-transform: uppercase;}
.mianbao a{ color:#333;}
.mianbao a:hover{ color:#e82023;}
.co00{ color:#e82023 !important}

.fymain{padding-top: 53px;padding-bottom: 100px;}
/**********   product1   **********/
.product1 .syptu{border: 1px solid #dcdcdc;}
.sypp2{position: relative;margin-top: 19px;}
.sypp2 input{cursor: pointer;position: absolute;left: 35px;top: 16px;}
.sypp2 a{display: block;width: 163px;height: 42px;line-height: 42px;text-align: center;border-radius: 5px;font-size:16px;font-size:1.6rem;border: 1px solid #e82023;color: #e82023;margin: 0 auto;}
.product1 .sypbox{margin-bottom: 50px;}
.sypp2 a:hover{background: #e82023;color: #fff;}

.ms_ye{position: relative;margin-top: 50px;text-align: center;}
.ms_ye a{display: inline-block;width: 33px;line-height: 28px;text-align: center;margin-left:2px;margin-right:3px;font-size: 12px;font-size: 1.2rem;background: #fff;color: #333333;border: 1px solid #e5e5e5;}
.ms_sx{width: 59px!important;background: none!important;color: #333!important;}
.ms_sx:hover{color: #e82023!important;}
.ms_ye .ms_current_ye{color: #e82023;border: 1px solid #e82023;}
.ms_ye a:hover{color: #e82023;border: 1px solid #e82023;}

.xiangshang{position: absolute;left: 0;bottom: 0;padding-left: 23px;background: url(../images/xs.png) no-repeat left 6px;}
.xiangshang input{width: 168px;height: 30px;line-height: 30px;text-align: center;color: #e82023;border:1px solid #e82023;cursor: pointer;background: #fff;}
.xiangshang input:hover{background: #e82023;color: #fff;}

.product1 .ms_ye{text-align: right;}

@media screen and (max-width:1219px) {
    .sypp2 input{left: 5px;}
}
@media screen and (max-width:999px) {
    .sypp2 input{left: 0px;top: 11px;}
    .sypp2 a{width: 124px;height: 34px;line-height: 34px;}
    .xiangshang{position: static;display: inline-block;}
    .product1 .ms_ye{text-align: center;}
    .ms_ye{margin-top: 20px;}
    .ms_ye a{margin-bottom: 10px;}
    .fymain{padding-top: 30px;padding-bottom: 40px;}
}
@media screen and (max-width:350px) {
    .sypp2 a{font-size:14px;font-size:1.4rem;width: 98px;}
}


/************  product2  *************/
#showArea{margin-top: 19px;margin-bottom:17px; }
#showArea img{cursor:pointer;width:117px;height:135px;border: 1px solid #dcdcdc;margin: 0 auto;}
#showArea a{ display:inline-block;text-align: left;margin-bottom: 8px;}
#showArea a:hover{background: url(../images/cxjt.png) no-repeat right center;}
#showArea a:hover img{border: 1px solid #e82023;}
#showArea a:hover{background: url(../images/youjt.png) no-repeat left center;}
#main_img{cursor:pointer;display:block;}
#gotop{cursor:pointer;display:block;  width: 16px;height: 8px;background: url(../images/top.png) no-repeat right center;margin: 0 auto;}
#gobottom{cursor:pointer;display:block; width: 16px;height: 8px;background: url(../images/bottom.png) no-repeat right center;margin: 0 auto;}
#gotop:hover{background: url(../images/topon.png) no-repeat right center;}
#gobottom:hover{background: url(../images/bottomon.png) no-repeat right center;}
#showArea{height:435px; overflow:hidden;}
.MagicZoomBigImageCont {background:#FFF;}
.MagicZoomBigImageCont img{width: 600px!important;height: 689.36px!important;display: block;}
.MagicZoomPup {border:0px solid #aaa;background:#ffffff;}

.left-pro{width:575px;text-align:left;margin-bottom:27px;margin-left: 22px;}
.left-pro .t1{width:119px;float:left;text-align: right;}
.left-pro .t2{width:423px;text-indent:0;float:right; border: 1px solid #dadada;}
.left-pro .t2 a{ display:block;margin-bottom: 0px;}
.left-pro .t2 img{text-indent:0;display: block;}

.content .title{margin-bottom: 22px;}
.content .title h1{font-size: 24px;font-size: 2.4rem;padding-bottom: 13px;line-height: 28px;color: #333333;}
.content{padding-top: 10px;width: 550px;}

.cpxx{line-height: 26px;color: #333333;font-size:16px;font-size:1.6rem;}
.cpxx a{color: #e82023;}
.cpxx img,.cpxx iframe{max-width: 100%;}
.cpxx strong{display: block;margin-top: 30px;margin-bottom: 10px;font-family: 'OPENSANSSEMIBOLD';font-size:18px;font-size:1.8rem;font-weight: normal;}
.btn{padding-top: 31px;}
.btn a{display: inline-block;width: 163px;height: 42px;line-height: 42px;text-align: center;border-radius: 5px;color: #e82023;transition: 0.5s;font-size: 16px;font-size: 1.6rem;border: 1px solid #e82023;}
.btn a:hover{background: #e82023;color: #fff;}
.btn1{margin-right: 35px;}
.share{padding-top: 46px;}
.share1{display: inline-block;position: relative;top: -5px;padding-right: 10px;font-size:16px;font-size:1.6rem;}
.share2{display: inline-block;}

.chanpinx{line-height: 26px;}
.chanpinx a{color: #e82023;}
.chanpinx img{max-width: 100%;}
.chanpinx iframe{max-width: 100%;}

.chanpinx table,.nex table,.cpxx table{ width:97%; border-collapse:collapse; line-height:24px;}
.chanpinx tr td,.nex tr td,.cpxx tr td{ line-height:24px; padding:2px;}

.table table{ width:100%; border-collapse:collapse; line-height:22px; border:1px solid #ccc;}
.table table tr td{ padding:3px; border:1px solid #ccc;}
@media screen and (max-width:1000px){
.table{ width:100%;  overflow:scroll;}
.table table{ width:885px;}	
}

.des{font-size: 30px;font-size: 3rem;margin-top: 44px;padding-bottom: 26px;background: url(../images/desbg.jpg) no-repeat left bottom;margin-bottom: 72px;font-family: 'OPENSANSSEMIBOLD';}

.img980 {position: relative;margin: 0 auto;width: 100%;}
.img980 .prev, .img980 .next {position: absolute;display: block;width: 16px;height: 25px;top:40%;color: #fff;text-align: center;line-height: 100px}
.img980 .prev {left: 0;cursor: pointer;background: url("../images/sbs.png") no-repeat;}
.img980 .next {right: 0;cursor: pointer;background: url("../images/sbx.png") no-repeat;}
.img980 .img-list {position:relative;width:90%;overflow: hidden;margin: 0 auto;}
.img980 .img-list ul {width: 10000%;}
.img980 .img-list li {float: left;display: inline;width: 0.31%;margin: 0 0.01%;position: relative;position: relative;text-align: center;}
.img980 .img-list li span,.img980 .img-list li a{color: #fff;display: block;border: 1px solid #dadada;}
.img980 .img-list li img{width: 100%;max-width: 390px;max-height: 390px;}

.img980{display: none;}

@media screen and (max-width:1219px) {
    .MagicZoomPup{background: rgba(0,0,0,0)!important;}
    .MagicZoomBigImageCont{display: none!important;}
    .left-pro{float: none;margin: 0 auto;}
    .content{width: 100%;padding-top: 30px;}
    .share{padding-top: 40px;}
    .btn{padding-top: 50px;}
}
@media screen and (max-width:999px) {
    .img980{display: block;}
    .left-pro{display: none;}
}
@media screen and (max-width:767px) {
    .des{font-size:24px;font-size:2.4rem;padding-bottom: 15px;margin-bottom: 30px;}
    .content .title h1{font-size:20px;font-size:2rem;}
    .content .title{margin-bottom: 5px;}
}
@media screen and (max-width:500px) {
    .img980 .img-list li{width: 0.48%;}
    .btn a{margin-bottom: 10px;}
    .cpxx{font-size:14px;font-size:1.4rem;}
}

/*********  about  *********/
.azuo img{width: 100%;max-width: 623px;max-height: 379px;}
.ayou{width: 540px;padding-top: 43px;}
.ap1{font-size:20px;font-size:2rem;color: #0f2c4b;padding-left: 29px;background: url(../images/shu.png) no-repeat left center;font-family: 'OPENSANSSEMIBOLD';}
.ap2{font-size: 30px;font-size: 3rem;text-transform: uppercase;font-family: 'OPENSANSSEMIBOLD';color: #0f2c4b;margin-top: 16px;margin-bottom: 14px;}
.ap3{font-size:16px;font-size:1.6rem;line-height: 30px;}
.ap4{font-size:16px;font-size:1.6rem;line-height: 30px;margin-top: 32px;}

.atulist{padding-top: 56px;}
.atulist li{float: left;width: 361px;margin-left: 58px;}
.atulist li img{width: 100%;max-width: 361px;max-height: 219px;}

@media screen and (max-width:1219px) {
    .azuo{width: 500px;}
    .ayou{width: 455px;padding-top: 0;}
    .ap3,.ap4{font-size:14px;font-size:1.4rem;}
    .atulist li{width: 314px;margin-left: 19px;}
}
@media screen and (max-width:999px) {
    .azuo{width: 100%;text-align: center;}
    .ap2{font-size:20px;font-size:2rem;}
    .ayou{width: 100%;padding-top: 20px;}
    .atulist li{width: 31.333%;margin: 0 1%;}
}
@media screen and (max-width:767px) {
    .atulist{padding-top: 25px;}
}
@media screen and (max-width:360px) {
    .atulist li{width: 98%;margin: 0 1%;margin-bottom: 10px;}
}

/***********  service1  ***********/
.abnav{ background:url(../images/abbiao.jpg) repeat-x left 55px; text-align:center;}
.abnav a{ display:inline-block; *float:left; width:120px; padding-top:130px; font-size:16px; font-size:1.6rem; line-height:24px; height:48px;margin:0 72px;}
.ab1{ background:url(../images/abbiao1.jpg) no-repeat center top;}
.ab2{ background:url(../images/abbiao2.jpg) no-repeat center top;}

.ab1:hover{ background:url(../images/abbiao1h.jpg) no-repeat center top;}
.ab2:hover{ background:url(../images/abbiao2h.jpg) no-repeat center top;}

.abnav a:hover{ color:#e82023;}
.ab1.dangqianab{ background:url(../images/abbiao1h.jpg) no-repeat center top !important;}
.ab2.dangqianab{ background:url(../images/abbiao2h.jpg) no-repeat center top;}

.dangqianab{ color:#e82023 !important;}
.lctu img{max-width: 100%;}
.lctu{padding-top: 85px;}

@media screen and (max-width:999px) {
    .lctu{padding-top: 40px;}
}
@media screen and (max-width:600px) {
    .abnav a{margin: 0 30px;}
}
@media screen and (max-width:400px) {
    .abnav a{padding-top: 100px;width: 100px;margin: 0 10px;}
    .abnav{background: none;}
    .abnav a.dangqianab{background-size:80%!important; }
    .abnav a:hover{background-size:80%!important; }
    .abnav a{background-size:80%; }
}

/***********  service2  ***********/
.ser2list{padding-top: 30px;}
.ser2list li{padding-bottom: 21px;border-bottom: 1px solid #7d7d7d;margin-bottom: 32px;}
.ser2list li span{display: block;padding-left: 36px;background: url(../images/biaoqian.png) no-repeat left top;font-size:16px;font-size:1.6rem;color: #e82023;font-family: 'OPENSANSSEMIBOLD';line-height: 24px;margin-bottom: 12px;}
.ser2list li p{font-size:16px;font-size:1.6rem;color: #555555;line-height: 30px;}
.ser2list li b{font-weight: normal;font-size:16px;font-size:1.6rem;color: #555555;display: block;margin-top: 30px;font-family: 'OPENSANSSEMIBOLD';margin-bottom: 4px;}

@media screen and (max-width:767px) {
    .ser2list li p{font-size:14px;font-size:1.4rem;}
}

/***********  service3  ***********/
.serzuo{padding-top: 14px;}
.serzuo img{max-width: 100%;}
.seryou{width: 840px;padding-top: 45px;}
.seryou p{font-size:16px;font-size:1.6rem;line-height: 24px;margin-bottom: 24px;}
.seryou2{margin-left: 30px;padding-top: 42px;}
.serzuo2{padding-top: 68px;}
.seryou2 img{width: 100%;max-width: 377px;max-height: 481px;}
.serp1{font-family: 'OPENSANSSEMIBOLD';font-size:24px;font-size:2.4rem;line-height: 24px;padding-left: 25px;background: url(../images/lankuai.jpg) no-repeat left center;margin-bottom: 27px;}
.serp2{font-size:16px;font-size:1.6rem;line-height: 30px;margin-bottom: 41px;}

@media screen and (max-width:1219px) {
    .seryou{width: 630px;}
}
@media screen and (max-width:999px) {
    .seryou{width: calc(100% - 340px);}
    .seryou2{width: 300px;}
    .seryou p{font-size:14px;font-size:1.4rem;}
    .serp2{font-size:14px;font-size:1.4rem;margin-bottom: 20px;}
    .serp1{margin-bottom: 15px;}
}
@media screen and (max-width:767px) {
    .serzuo{width: 100%;text-align: center;padding-top: 0;}
    .seryou{width: 100%;padding-top: 25px;}
    .seryou p{margin-bottom: 15px;}
    .seryou2{width: 230px;}
    .serp1{font-size:20px;font-size:2rem;}
    .serzuo2{padding-top: 35px;}
}
@media screen and (max-width:550px) {
    .seryou2{width: 100%;text-align: center;margin-left: 0;float: none;padding-top: 25px;}
    .serzuo2{padding-top: 20px;}
}

/***********  service4  ***********/
.ser4p1{font-family: 'OPENSANSSEMIBOLD';font-size: 30px;font-size: 3rem;text-transform: uppercase;}
.ser4p2{font-size:16px;font-size:1.6rem;line-height: 30px;margin-top: 21px;margin-bottom: 62px;}
.ser4tu img{display: block;width: 100%;max-width: 1200px;}

@media screen and (max-width:767px) {
    .ser4p1{font-size:24px;font-size:2.4rem;}
    .ser4p2{font-size:14px;font-size:1.4rem;margin-top: 10px;margin-bottom: 20px;}
}

/***********  cooperation  ***********/
.coopbt{text-align: center;font-size: 30px;font-size: 3rem;text-transform: uppercase;font-family: 'OPENSANSSEMIBOLD';margin-bottom: 32px;}
.coopbt span{color: #e82023;}
.coopzuo{padding-top: 10px;}
.coopzuo img{max-width: 100%;}
.coopyou{width: 830px;margin-bottom: 50px;}
.coopyou p{line-height: 30px;color: #555555;}
.xian{border-bottom: 3px solid #e5e5e5;margin-bottom: 44px;}
.coopzuo2{width: 860px;padding-top: 5px;}
.coopzuo2 p{color: #555555;line-height: 30px;}
.coopyou2 img{max-width: 100%;}
.related{text-align: center;font-size: 30px;font-size: 3rem;text-align: center;padding-bottom: 27px;background: url(../images/relatebg.jpg) no-repeat center bottom;font-family: 'OPENSANSSEMIBOLD';text-transform: uppercase;margin-top: 118px;margin-bottom: 72px;}

@media screen and (max-width:1219px) {
    .coopyou{width: 710px;}
    .coopzuo2{width: 690px;}
    .related{margin-top: 70px;}
}
@media screen and (max-width:999px) {
    .coopyou{width: calc(100% - 250px);}
    .coopzuo2{width: calc(100% - 280px);padding-top: 0;}
}
@media screen and (max-width:767px) {
    .coopbt{font-size:24px;font-size:2.4rem;margin-bottom: 15px;}
    .related{font-size:24px;font-size:2.4rem;margin-top: 40px;margin-bottom: 30px;}
    .coopzuo{float: none;padding-top: 0;}
    .coopyou{width: 100%;margin-bottom: 20px;}
    .coopzuo2{width: 100%;margin-top: 20px;}
    .coopyou2{width: 100%;}
    .xian{margin-bottom: 30px;}
}


/**********  news1  **********/
.new1 .fwp{margin-bottom: 42px; padding-bottom: 23px;  position: relative;border-bottom: 1px solid #bfbfbf;}
.new1 .swp{ position: absolute; top:0; left: 0;  }
.new1 .swp a{ display: block; width: 100%; margin: 0 auto; }
.new1 .swp a img{ display: block; max-width: 274px; max-height: 178px; width: 100%; margin: 0 auto; }

.new1 .zi {  padding-left: 298px; min-height: 178px; padding-right: 0px; }
.new1 .zi .time{ padding-bottom: 18px; padding-top: 13px;  font-size: 14px; font-size: 1.4rem; line-height: 30px;color: #888888;   }
.new1 .zi .title{  color: #333; display: block; line-height: 26px; font-size: 18px; font-size: 1.8rem;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.new1 .zi .p{ display: block;  margin-bottom: 17px; font-size: 14px; font-size: 1.4rem; color: #555555; line-height: 26px; height: 78px;overflow: hidden;}
.new1 .zi .more{ display: block;  font-size: 14px; font-size: 1.4rem;  width: 113px; height: 34px; line-height: 34px; text-align: center; color: #fff; background: #e82023; border-radius: 50px; }
.new1 .zi .title:hover{ color: #e82023;}
.new1 .zi .p:hover{ color: #e82023;}

.new1 .zi .more:hover{ background: #333; coolor: #fff; }

.new1 .page { position: relative; margin: 69px auto 0;}

@media screen and (max-width:999px){
.new1 .fwp{padding-bottom: 15px;margin-bottom: 20px;}
.new1 .page{margin: 45px auto 0;}
.new1{padding-bottom: 60px;}
}
@media screen and (max-width:700px){
    .new1 .fwp{ padding-bottom: 0px; }
    .new1 .swp{ position: relative; top:0; left: 0;  }
    .new1 .zi {  padding-left: 0px; min-height: 236px; padding-right: 0px; padding-top: 15px;min-height: auto;text-align: center;}
    .new1 .zi .title{padding-top: 10px;}
    .new1 .page{margin: 20px auto 0;}
    .new1 .zi .time{padding-bottom: 5px;}
    .fwp .time{float: none;}
}

/**********  news2  **********/
.news2 h1 { font-size: 24px; font-size: 2.4rem; margin-bottom: 27px;line-height: 26px; }

.kejisj { color: #888888;}

.kejisj span { color: #888888;}

.nex { line-height: 26px; padding-top: 31px; padding-bottom: 60px; margin-bottom: 55px;color: #555555; border-bottom: 1px solid #bfbfbf;}

.nex p, .nex li, .nex span, .nex b { color: #555555; }

.nex a { color: #e82023; }

.nex img, .nex iframe { max-width: 100%; }

.xwsx { line-height: 28px; margin-top: 12px;font-size:16px;font-size:1.6rem; color: #666666;}

.xwsx span { display: inline-block;margin-right: 7px; color: #333333; padding-left: 38px;}

.xws{background: url(../images/xws.png) no-repeat left center;}

.xwx{background: url(../images/xwx.png) no-repeat left center;}

.xwsx a { font-size: 16px; font-size: 1.6rem; color: #666666; }

.xwsx a:hover { color: #333; }

@media screen and (max-width: 767px) { .news2 h1 { line-height: 24px; font-size: 16px; font-size: 1.6rem; }
  .nex { padding-bottom: 40px; margin-bottom: 20px; padding-top: 20px; font-size: 14px; font-size: 1.4rem; }
  .xwsx a { font-size:14px;font-size:1.4rem; } 
  .xwsx{font-size:14px;font-size:1.4rem;}
}

/***********  contact  ***********/
/* contact_top */
.contact_top{ background: #e5e5e5; border-radius: 8px;}


/* c_left */
.c_left{ width: 465px; padding-top: 52px; padding-bottom: 80px; background: url( ../images/conleft1.png ) no-repeat left top; border-radius: 8px 0px 0px 8px;   }
.c_left_wrap{ width: 360px; margin: 0 auto; }
.c_left_wrap .c_title{ padding-bottom: 29px;font-size:16px;font-size:1.6rem; color: #fff;  line-height: 24px; }

.c_title4 span{display:block;font-size:20px;font-size:2rem;font-family: 'OPENSANSSEMIBOLD';color:#fff;line-height: 36px;}
.c_title4 span.span2{margin-top: 22px;}
.c_title4 p{font-size:16px;font-size:1.6rem;line-height: 36px;color: #fff;}
.c_title4 p a{color: #fff;}
.c_title4 p a:hover{color: #e82023;}

/*
font-family: 'OPENSANSSEMIBOLD';
*/


/* c_right */
.c_right{ float:right; width: 47%;  margin-right: 84px;}

.c_title2{ padding-top: 23px; padding-left:14px; line-height:30px;font-family: 'OPENSANSSEMIBOLD';font-size:24px; font-size:2.4rem; color:#e82023; text-transform:uppercase; padding-bottom:3px; }
.c_xp{ padding-top:13px; }
.c_bt{ width:14px; float:left; color:#ff0000; font-family:Verdana; line-height:36px; height:36px; font-size:14px; font-size:1.4rem; }

.c_div1{ padding-bottom:18px; }
.c_div2{ padding-bottom:20px; }
.c_div3{ padding-bottom:20px; }
.c_div4{ padding-top:2px; margin-bottom: 5px;}
.c_input1{ float:left; line-height:35px; height:35px; font-size: 12px; font-size: 1.2rem; text-indent:14px; color:#666666; width:547px; border:solid 1px #cccccc; background:#fff; }
.c_textarea{ float:left;font-size: 12px; font-size: 1.2rem;   text-indent:14px; color:#666666; width:544px; border:solid 1px #cccccc; line-height:26px; padding-top:1px; height:211px; background:#fff; }
.c_captcha{ font-size: 12px; font-size: 1.2rem; background:#fff; width:154px; float:left; line-height:35px; height:35px; text-indent:9px; color:#666666; border:solid 1px #cccccc;  }
.c_yzm{ float:left; margin-left:19px; cursor:pointer; }
.c_send{ float:right; text-align:center; color:#fff; background:#333333; width:181px; height:37px; line-height:35px;
font-size:14px; font-size:1.4rem; cursor:pointer;}
.c_send:hover{ }

.map{margin: 50px auto 0;  }
.map img{ display: block; max-width: 1200px; max-height: 381px;  width: 100%; }
@media screen and (max-width:1219px){

.c_left{ width: 399px; }
 
.c_div1,.c_div2,.c_div3{ padding-bottom:16px; }
.c_input1{ width:95%; }
.c_textarea{ width:95%; }
.c_send{ width:116px;  }	
}
@media screen and (max-width:999px){
    .contact_top{ background: #e5e5e5;   margin-top: 20px;}
    .map{margin: 20px auto 20px;  }
.c_left{ float:none; width:95%; margin:0 auto; max-width:399px; border-radius: 10px; margin-top: 20px; padding-bottom: 20px;  padding-top: 20px; }
.c_right{ float:none; width:95%; margin:0 auto; margin-top:20px; }
.c_title2{ padding-top: 0px; }
.c_left_wrap { width: 90%; }
.c_send{ width:95%;  margin-left: 14px; margin-top: 15px; float: left; }
.c_left_wrap .c_title{ padding-bottom: 20px; }
}
@media screen and (max-width:419px){
    .c_left_wrap .c_tiyl{ margin-bottom: 15px; }
    .c_input1{ width:90%; }
    .c_textarea{ width:90%; }
    .c_send{ width:90%; }
    .c_captcha{ width:90%; margin-bottom:20px; }
    .c_yzm{ margin-left:12px; }	
}


/***********  xiala  ***********/
.yiji{position: absolute;z-index: 9999;width: 235px;background: #fff;border: 1px solid #e5e5e5;top: -9999px;left: 50%;margin-left: -118px;padding-bottom: 6px;padding-top: 6px;}
.yiji li{position: relative;padding-left: 4px;padding-right: 4px;}
.yiji li a{display: block;color: #333;line-height: 50px;padding-left: 16px;padding-right: 10px;font-size:18px;font-size:1.8rem;}
.yiji li:hover>a{background:#e82023;color: #fff;}

.erji{position: absolute;left: 100%;top: -999px;width: 235px;background: #fff;border: 1px solid #e5e5e5;padding-top: 6px;padding-bottom: 6px;}
.navlist>li:hover .yiji{top: 100%;}
.yiji>li:hover .erji{top: -7px;}
@media screen and (min-width:1000px) and (max-width:1219px) {

}
@media screen and (max-width:999px) {
    .yiji{display: none;}
}


.ce{position: fixed;right: 0px;top: 30%;width: 50px;z-index: 9999999999;}
.ce li{height: 50px;transition: 0.5s;}
.ce li a{display: block;height: 50px;}

.ce1{background: #1672d5 url(../images/cei1.png) no-repeat center center;}
.ce2{background: #00aff0 url(../images/cei2.png) no-repeat center center;}


@media screen and (max-width:767px) {
    .ce{bottom: 0;top: auto;width: 100%;}
    .ce li{width: 50%;margin: 0;float: left;height: 40px;border-radius: 0;border-radius: 0;box-shadow: none;}
    .link{padding-bottom: 40px;}
}

.hdtop{position: fixed;right: 20px;bottom: -100px;transition: 1s;cursor: pointer;z-index: 99999;}






